<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'Preloader',
  setup (props, { expose }) {
    const visible = ref(true)
    expose({ visible })
    return { visible }
  }
})
</script>

<template>
  <div id="app-loading-container">
    <transition>
      <div v-if="visible" id="app-loading">
        <span class="loader"></span>
      </div>
    </transition>
  </div>
</template>

<style lang="scss">
#app-loading {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: color('grey-dark');
  color: color('intro');
}

.loader {
  position: relative;
  width: rem(70px);
  height: rem(70px);
}

.loader::before , .loader::after{
  position: absolute;
  border-radius: 50%;
  content: '';
  inset: 0;
}

.loader::after {
  animation: rotate 2s linear infinite;
  box-shadow: 0 2px 0 color('intro') inset;
}

@keyframes rotate {
  0% {  transform: rotate(0)}
  100% { transform: rotate(360deg)}
}
</style>
